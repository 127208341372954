import React, { useEffect, useRef, useState } from "react";
import { uniq } from "lodash";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";

import { naturalSort } from "../../frontend-shared/helpers";

import ButtonGetStarted from "../Buttons/ButtonGetStarted";
import ButtonWatchDemo from "../Buttons/ButtonWatchDemo";
import Link from "../Elements/Link";
import Select from "../Elements/Select";

const isOthers = RegExp.prototype.test.bind(/^others$/i);

const Partner = ({ description, logo, ...rest }) => {
  const ref = useRef(null);
  const [expand, setExpand] = useState(false);
  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = expand
        ? `${ref.current.firstChild.getBoundingClientRect().height}px`
        : '0px';
    }
  }, [expand]);
  return (
    <div>
      <button
        className="bg-white px-4 rounded-2xl shadow-md hover:shadow-lg flex flex-col items-center justify-center w-full"
        onClick={() => setExpand(!expand)}
        type="button"
      >
        <img
          alt={logo?.alt}
          className="mb-0 py-16 select-none"
          height={Math.round(logo?.dimensions.height / 2)}
          loading="lazy"
          src={logo?.fluid.srcWebp}
          width={Math.round(logo?.dimensions.width / 2)}
         />
         <div ref={ref} className="overflow-y-hidden transition-all ease-in-out delay-100">
           <div className="flex flex-col text-blue text-lg text-start pb-8">
             <PrismicRichText field={description?.richText} />
             <div className="py-6" />
             <div className="flex flex-col gap-2">
               {[1, 2, 3].map((index) => {
                 const {
                   [`button_${index}_text`]: text,
                   [`button_${index}_link`]: link,
                 } = rest;
                 return text && (
                   <Link key={text} href={link} className="p-3 no-underline block rounded-md hover:bg-gray-50">
                     <div className="text-sm uppercase tracking-wider font-medium text-blue mb-0">{text}</div>
                   </Link>
                 );
               })}
             </div>
           </div>
         </div>
      </button>
    </div>
  );
};

const HeroSection = ({
  slice: {
    primary: {
      heading,
      sub_heading,
    },
    items,
  },
}) => {
  const [partnerFilter, setPartnerFilter] = useState('.*');
  const test = RegExp.prototype.test.bind(
    new RegExp(`^${partnerFilter}$`, 'i'),
  );
  return (
    <div className="background1">
      <div className="w-full md:py-52 pt-36 pb-36 relative">
        <div className="md:block hidden absolute -bottom-20 right-0">
          <StaticImage src="../../assets/images/Partners/partners-Logo5.png" alt="" width="210" height="260" />
        </div>
        <div className="md:hidden block  absolute -bottom-20 right-0">
          <StaticImage src="../../assets/images/Partners/partners-Logo6.png" alt="" width="100" height="200" />
        </div>
        <div className="max-w-7xl mx-auto px-4">
          <h1 className="md:text-center text-left m-0 lg:text-7xl md:text-6xl sm:text-5xl text-4xl text-blue font-medium tracking-tighter mb-9">{heading && heading.text}</h1>
          <p className="md:text-center mx-auto text-left text-xl font-normal text-blue">{sub_heading && sub_heading}</p>
          <form className="formblock mx-auto max-w-sm">
            <Select
              onChange={({ target }) => setPartnerFilter(target.value)}
              options={[{
                label: 'All Partners',
                value: '.*',
              }, ...uniq(
                [...new Set(items.map(({ partner_type: type }) => type))],
              ).sort((x, y) => isOthers(x) ? (
                isOthers(y) ? naturalSort(x, y) : 1
              ) : (
                isOthers(y) ? -1 : naturalSort(x, y)
              )).map(
                (value) => ({ label: value, value }),
              )]}
              value={partnerFilter}
            />
          </form>
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 flex flex-row gap-5 mt-16">
            {items?.filter(({ partner_type: type }) => test(type)).map((item) => (
              <Partner {...item} />
            ))}
          </div>
          <div className="flex items-center justify-center md:mt-14 mt-10  gap-4 md:flex-row flex-col">
            <ButtonGetStarted />
            <ButtonWatchDemo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

export const query = graphql`
fragment partnersHeroSlice on PrismicPartnersDataBodyHero {
  id
  slice_type
  primary {
    sub_heading
      heading {
        text
        html
      }
  }
  items {
    logo {
      alt
      dimensions {
        width
        height
      }
      fluid(maxWidth: 186, maxHeight: 96) {
        srcWebp
      }
    }
    partner_type
    description {
      richText
    }
    button_1_text
    button_1_link
    button_2_text
    button_2_link
    button_3_text
    button_3_link
  }
}
`
